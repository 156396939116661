export const frozenTime = [
  {
    label: '永久',
    value: 3
  },
  {
    label: '7天',
    value: 1
  },
  {
    label: '30天',
    value: 2
  }
]

export const frozenReason = [
  {
    label: '上传违法违规图片',
    value: 'uploadViolation'
  },
  {
    label: '多次取消订单',
    value: 'badCancel'
  },
  {
    label: '多次账号恶意维权',
    value: 'badMaintain'
  },
  {
    label: '其他原因',
    value: 'otherReason'
  }
]